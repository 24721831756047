/* eslint-disable */
import Vue from 'vue'
import Header from '../../Header.vue'
import Footer from'../../Footer.vue'
import NoData from '../../../components/NoData.vue'
import '../../../util/layui/css/layui.css'
import '../../../util/layui/layui.js'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'ManuscriptEditOne',
	data(){
		return{
			keyword:'',
			keywordList:[],
			ManuName:'',
			userInfo:'',//用户信息
			ManuElementList:[],//词条
			ManuEntryList:[],//文章
			curClassType:0
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			CheckInService.GetUserPerInfo(that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.userInfo = res.Result
				}
			})
		},
		GetKeywords(){ //获取关键词
			var that = this
			CheckInService.GetKeywords(that.keyword.trim()).then(res=>{
				if(res.Tag == 1){
					if(that.keywordList.length != 0){
						that.keywordList = res.Result
						$(".label-item").find("li").remove(); 
						var html = '';
						for(var i in that.keywordList) {
							html += "<li data=\"" + that.keywordList[i].id + "\"><span>" + that.keywordList[i].element_name + "</span></li>"; //拼接标签
						}
						$(".label-item").append(html);
					}else{
						that.keywordList = res.Result
					}
				}
			})
		},
		ChangeKeyword(){ //换一批关键词
			this.keyword = ''
			this.GetKeywords()
		},
		GetManuElementList(){ //获取相关知识元
			var that = this
			var keywords = $("#keywords").val()
			if(keywords == ''){
				that.ManuElementList = []
				return
			}
			CheckInService.GetManuElementList(keywords,10).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.ManuElementList = res.Result
				}
			})
		},
		GetManuEntryList(){ //获取相关条目
			var that = this
			var keywords = $("#keywords").val()
			if(keywords == ''){
				that.ManuEntryList = []
				return
			}
			CheckInService.GetManuEntryList(keywords,10).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.ManuEntryList = res.Result
				}
			})
		},
		changeType(type){ //切换分类
			if(type == 0){
				this.GetManuElementList()
			}else{
				this.GetManuEntryList()
			}
			this.curClassType = type
		},
		toNext(){ //下一步
			var that = this
			var keywords = $("#keywords").val()
			var keywordNames = $("#keywordnames").val();
			if(that.ManuName.trim() == ''){
				$('#ManuName input').addClass('error')
				$('#ManuName .zxtgerror').text('请输入选题名称')
				return
			}else if(that.ManuName.trim().length > 30){
				$('#ManuName input').addClass('error')
				$('#ManuName .zxtgerror').text('请输入限于30字的选题名称')
				return
			}else if(!keywords){
				$('#ManuName input').removeClass('error')
				$('#ManuName .zxtgerror').text('')
				$('#ManuKeyword .label-selected').addClass('error')
				$('#ManuKeyword .zxtgerror').text('请选择关键词，限5个')
				return
			}
			$('#ManuName input').removeClass('error')
			$('#ManuName .zxtgerror').text('')
			$('#ManuKeyword .label-selected').removeClass('error')
			$('#ManuKeyword .zxtgerror').text('')
			CheckInService.AddManuscript(that.ManuName.trim(),keywords,keywordNames,that.$UserID,that.userInfo.UserName,that.userInfo.Tel,that.userInfo.Sex?that.userInfo.Sex:0,that.userInfo.BirthdatName,that.userInfo.Address?that.userInfo.Address:'').then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.$router.push({
						path:'/ManuscriptEdit',
						query:{
							id:res.Result.ID 
						}
					})
				}
			})
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl + 'ElementDetail?id='+id)
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl + 'EntryDetail?id='+id)
		},
		ShowKeyWords(){
			var that = this
			$(function() {
				$(".show-labelitem").on("click", function() {
					$(this).hide();
					$(".hide-labelitem").show();
					$("#labelItem").show();
				});
				$(".hide-labelitem").on("click", function() {
					$(this).hide();
					$(".show-labelitem").show();
					$("#labelItem").hide();
				});
				$(".label-item").on("click", "li", function() {
					var id = $(this).attr("data");
					var text = $(this).children("span").html();
					var labelHTML = "<li data='" + id + "'"+"data-name='" + text +  "'>" + text + " x</li>";
					if($(this).hasClass("selected")) {
						return false;
					} else if($(".label-selected").children("li").length >= 5) {
						layer.msg("最多可以选择5个哦");
						return false;
					}
					$(".label-selected").append(labelHTML);
					val = '';
					valName = '';
					for(var i = 0; i < $(".label-selected").children("li").length; i++) {
						val += $(".label-selected").children("li").eq(i).attr("data") + ',';
						valName += $(".label-selected").children("li").eq(i).attr("data-name") + ',';
					}
					$("#keywords").val(val);
					$("#keywordnames").val(valName);
					$(this).addClass("selected");
					that.GetManuElementList()
					that.GetManuEntryList()
				});
				var val = "";
				var valName = "";
				$(".label-selected").on("click", "li", function() {
					var id = $(this).attr("data");
					val = '';
					valName = '';
					$(this).remove();
					for(var i = 0; i < $(".label-selected").children("li").length; i++) {
						val += $(".label-selected").children("li").eq(i).attr("data") + ',';
						valName += $(".label-selected").children("li").eq(i).attr("data-name") + ',';
					}
					$("#keywords").val(val);
					$("#keywordnames").val(valName);
					$(".label-item").find("li[data='" + id + "']").removeClass("selected");
					that.GetManuElementList()
					that.GetManuEntryList()
				});
			})
		}
	},
	created(){
		this.GetUserInfo()
		this.GetKeywords()
	},
	mounted(){
		this.ShowKeyWords()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'NoData':NoData
	}
}